<template>
    <div class="mt-5 px-xl-5">
        <h6 class="page-title pb-2 text-uppercase">
            <b-button class="main-btn rounded me-2" @click="goSuperitendences">
                <b-icon icon="arrow-left" />
            </b-button>
            {{ $t("superintendence.title") }}
        </h6>
        <b-row class="mt-3">
            <b-col>
                <b-card border-variant="light" class="shadow-sm" header-class="border-0">
                    <template #header>
                        <h6 class="mb-0 details-title-value">
                            {{ $t("superintendence.sub-title") }}
                        </h6>
                    </template>
                    <b-card-text>
                        <b-row v-if="isLoading" class="justify-content-center my-2">
                            <b-spinner variant="primary" />
                            <h6 class="page-title text-center mt-2">
                                {{ $t("superintendence.loading") }}
                            </h6>
                        </b-row>
                        <b-row v-if="!isLoading">
                            <b-col lg="6">
                                <SuperintendenceProperty :label="$t('general.name')" :value="superintendence.name" />
                                <SuperintendenceProperty :label="$t('general.email')" :value="superintendence.email" />
                                <SuperintendenceProperty :label="$t('general.phone')" :value="superintendence.phone" />
                                <SuperintendenceProperty :label="$t('general.identification')"
                                    :value="superintendence.identification" />
                                <SuperintendenceProperty :label="$t('general.creation-date')"
                                    :value="superintendence.creationDate" />
                                <SuperintendenceProperty :label="$t('general.roles')" :value="roles" />
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <div v-show="superintendence && !superintendence.isLoggedUser">
            <SuperintendencePermissions v-if="!isLoading" :superintendence="superintendence" />
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";
import { mapActions } from "vuex";

import SuperintendenceProperty from "./SuperintendenceProperty.vue";
import SuperintendencePermissions from "./SuperintendencePermissions.vue";

import { parseRoles } from "@/utils/parse.js"

export default {
    name: "superintendence",
    components: {
        SuperintendenceProperty,
        SuperintendencePermissions,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLoading: true,
            superintendence: null,
            roles: ''
        };
    },
    mounted() {
        this.retrieveSuperintendece();
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async retrieveSuperintendece() {
            this.isLoading = true;
            try {
                let response = await RepositoryFactory.superintendence.get({
                    id: this.id,
                });

                let superintendence = response.data;

                superintendence.creationDate = this.parseAndConvertDate(
                    superintendence.creationDate
                );
                this.roles = parseRoles(superintendence.roles)
                this.superintendence = superintendence;
            } catch {
                this.showError();
            }
            this.isLoading = false;
        },
        showError() {
            this.setModalInfo({
                title: this.$t(`superintendence.error.title`),
                message: this.$t(`superintendence.error.message`),
                subMessage: this.$t(`superintendence.error.subMessage`),
            });
            this.openModal();
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        goSuperitendences() {
            this.$router.push({
                name: "superintendences",
            });
        },
    },
};
</script>