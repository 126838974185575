<template>
    <Superintendence :id="id" />
</template>

<script>
import Superintendence from "../components/superintendence/Superintendence.vue";

export default {
    name: "superintendence-view",
    components: {
        Superintendence,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
};
</script>